import React, { FC } from 'react';

import { ImCross, ImCheckmark } from 'react-icons/im';
import { AiOutlineClose } from 'react-icons/ai';

import { Loading } from '../Loading';
import { ConfirmModalProps } from './types';
import useConfirmModalController from './useConfirmModalController';

import {
  Container,
  ContentModal,
  Description,
  DescriptionText,
  Tag,
  ContentTitle,
  Title,
  IconsContent,
  Icon,
  NameText,
  ButtonClose,
} from './styles';

export const ConfirmModal: FC<ConfirmModalProps> = ({
  open,
  onClose,
  handleCancel,
  invalidateCheckinQuery,
}) => {
  const {
    handleClose,
    isConfirm,
    clientName,
    gymClassName,
    loading,
    handleConfirmAwaitingCheckin,
    handleManualRefundCheckinCheckin,
    reservationHours,
  } = useConfirmModalController({
    open,
    onClose,
    handleCancel,
    invalidateCheckinQuery,
  });

  if (!open) {
    return null;
  }

  return (
    <Container onClick={handleClose}>
      <ContentModal onClick={e => e.stopPropagation()}>
        <ButtonClose onClick={handleClose}>
          <AiOutlineClose size={30} color="#818181" className="close" />
        </ButtonClose>
        <ContentTitle>
          <Title>
            {isConfirm ? (
              <strong>Confirmar a reserva de:</strong>
            ) : (
              <strong>Confirmar cancelamento da reserva de:</strong>
            )}
          </Title>
        </ContentTitle>

        <Description>
          <DescriptionText>
            <NameText>{clientName}</NameText>
            <strong>Reserva para {reservationHours}</strong>
          </DescriptionText>
        </Description>

        <IconsContent>
          <Tag>{gymClassName}</Tag>

          {isConfirm ? (
            <Icon
              bgColor="#D8F32E"
              onClick={() => handleConfirmAwaitingCheckin()}>
              {!loading ? (
                <ImCheckmark color="#fff" size={20} />
              ) : (
                <Loading width={30} height={30} borderColor="#fff" border={4} />
              )}
            </Icon>
          ) : (
            <Icon
              bgColor="#606060"
              onClick={() => handleManualRefundCheckinCheckin()}>
              {!loading ? (
                <ImCross color="#fff" size={20} />
              ) : (
                <Loading width={30} height={30} borderColor="#fff" border={4} />
              )}
            </Icon>
          )}
        </IconsContent>
      </ContentModal>
    </Container>
  );
};

export default ConfirmModal;
